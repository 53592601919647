function isToday(date) {
  const today = new Date();
  return today.toDateString() === new Date(date).toDateString();
}

function isYesterday(date) {
  const today = new Date();
  today.setDate(today.getDate() - 1);

  return today.toDateString() === new Date(date).toDateString();
}

export function formatNewsDate(date) {
  if (isToday(date)) {
    const formattedDate = new Date(date).toLocaleString('ru-RU', {
      hour: '2-digit',
      minute: '2-digit',
    });
    return `Сегодня ${formattedDate}`;
  }

  if (isYesterday(date)) {
    const formattedDate = new Date(date).toLocaleString('ru-RU', {
      hour: '2-digit',
      minute: '2-digit',
    });
    return `Вчера ${formattedDate}`;
  }

  return new Date(date).toLocaleString('ru-RU', {
    month: 'long',
    day: 'numeric',
  });
}

export function formatNewsDay(date) {
  const newsDate = new Date(date);
  const currentDate = new Date();
  let formattedDate = new Date(date).toLocaleString('ru-RU', {
    month: 'long',
    day: 'numeric',
  });

  if (isToday(date)) {
    return `Сегодня`;
  }

  if (isYesterday(date)) {
    return `Вчера`;
  }

  if (newsDate.getFullYear() !== currentDate.getFullYear()) {
    formattedDate += ` ${newsDate.getFullYear()}`;
  }

  return formattedDate;
}

export function formatNewsTime(date) {
  const formattedDate = new Date(date).toLocaleString('ru-RU', {
    hour: '2-digit',
    minute: '2-digit',
  });
  return `${formattedDate}`;
}
